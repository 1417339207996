import React, { useState } from 'react';
import './faq.css'; 

function Faq() {
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  const faqs = [
    {
      question: "How long does it take to build a website, and what’s included?",
      answer:
        "Project timelines vary based on the complexity of the website, but generally range from 4-12 weeks. Our web development packages include design, development, mobile responsiveness, basic graphic design setup, and post-launch support.",
    },
    {
      question: "How do you approach digital marketing strategy?",
      answer:
        "We begin by analyzing your target audience, competition, and business goals. From there, we develop a customized strategy that integrates graphic design, content marketing, social media, <a href='/google-facebook-ads' style='color: blue; text-decoration: underline;'>paid advertising</a>, and more to drive measurable results.",
    },
    {
      question: "How will I know if my digital marketing campaigns are successful?",
      answer:
        "We track KPIs such as traffic, engagement, conversions, and ROI, and provide regular reports to keep you informed. Our team works closely with you to adjust strategies as needed to achieve the best possible outcomes.",
    },
    {
      question: "What is your web development process like?",
      answer:
        "Our web development process includes planning, design, development, and testing. We focus on creating user-friendly, responsive, and graphic designed websites that align with your brand identity and business goals. We also ensure ongoing support post-launch.",
    },
  ];

  return (
    <div className="faq">
      <h2 className="faq-title">
        Explore Common <br />
        <span className="highlight">Questions</span>
      </h2>
      <div className="faq-container">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${openItem === index ? 'open' : ''}`}
          >
            {/* Question and Arrow */}
            <div
              className="faq-question"
              onClick={() => toggleItem(index)}
            >
              {faq.question}
              <span className="arrow">{openItem === index ? '▲' : '▼'}</span>
            </div>

            {/* Render Answer Conditionally */}
            {openItem === index && (
              <div
                className="faq-answer"
                dangerouslySetInnerHTML={{ __html: faq.answer }}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faq;
