import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import BlogCardCarousel from './BlogCard'; // Import the carousel component
import './Blogs.css'; // Optional styling file

// Sample blog data
const blogs = [
  {
    id: 1,
    title: "7 Reasons Why Performance Marketing Is A Game Changer For Your Business",
    slug: "why-performance-marketing-is-a-game-changer", 
    image: "/blog-img.webp",
    preview: "In a rapidly changing digital world, businesses are constantly searching for marketing strategies that deliver measurable results and increase ROI...",
    fullContent: `
       <p>In a rapidly changing digital world, businesses are constantly searching for marketing strategies that deliver measurable results
      and increase return on investment (ROI). Enter performance marketing. It's a records-driven technique that is revolutionizing the
      way agencies advertise online. This isn't the same as conventional advertising techniques. There's regularly no clarity about
      fulfillment. Performance advertising guarantees that you best pay for precise results such as clicks, leads, or income.</p>
      
      <p>At Saptam Technologies, we trust in empowering companies with revolutionary answers. And at the heart of all of it is performance marketing.
      Let's explore why display marketing is a game changer for your business!</p>

      <h2>1. Pay only for results 💸</h2>
      <p>One of the biggest drawbacks to traditional marketing is campaign effectiveness. You will need to pay in advance. Display marketing
      is floating around in this story. You only pay when certain actions occur, such as visiting a website, lead generation, or sales.</p>
      <ul>
        <li>Maximize ROI with transparent costs.</li>
        <li>Budget-friendly for organizations of all sizes.</li>
      </ul>
      <p><strong>Example:</strong> Running a pay-in-step-with-click (PPC) advertising campaign means that you most effectively pay while someone clicks
      on your advert—not for display or access.</p>

      <h2>2. Measurable results with real-time insights 📊</h2>
      <p>In performance marketing, data is essential. Advanced tools such as Google Analytics, Facebook Ads Manager, and conversion
      tracking software allow you to track all your clicks, conversions, and income in actual time. This transparency helps you make
      records-driven decisions and optimize your campaigns for better results.</p>
      <p><strong>Why it's a game changer:</strong></p>
      <ul>
        <li>Learn what works and what doesn't.</li>
        <li>Adjust now for maximum performance.</li>
      </ul>
      <p><strong>Pro Tip:</strong> Use A/B testing to determine which ads or strategies work best for your audience.</p>

      <h2>3. Set the target group appropriately 🎯</h2>
      <p>Finding the right audience is critical to business success, and display marketing allows for precise targeting. Whether it’s
      demographics, interests, behavior, or location, platforms like Google Ads and Facebook Ads let you focus your campaigns effectively.</p>
      <ul>
        <li>No more spending money on ads with unrelated audiences.</li>
        <li>Higher conversion likelihood with targeted campaigns.</li>
      </ul>
      <p><strong>Example:</strong> An e-commerce business that sells exercise equipment can specifically target its advertising to fitness enthusiasts
      aged 20-35 in metropolitan areas.</p>

      <h2>4. Increase brand visibility and awareness 🌟</h2>
      <p>Although performance marketing focuses on results, it also increases brand awareness. Each click or engagement increases your brand's visibility
      and familiarizes potential customers with your business. Over time, this strengthens brand recognition and credibility.</p>
      <ul>
        <li>Increase trust and brand awareness.</li>
        <li>It puts your business at the forefront of your niche.</li>
      </ul>
      <p><strong>Pro Tip:</strong> Combine display marketing with content marketing to create an overall strategy for long-term brand building.</p>

      <h2>5. Multi-channel flexibility 🌐</h2>
      <p>Performance marketing covers many channels, such as:</p>
      <ul>
        <li>Search Engines (Google Ads, Bing Ads)</li>
        <li>Social media (Facebook, Instagram, LinkedIn)</li>
        <li>Affiliate Marketing</li>
        <li>Native advertising</li>
      </ul>
      <p>This multi-channel approach ensures that your message reaches potential customers wherever they are online.</p>
      <ul>
        <li>Different sessions for higher results.</li>
        <li>Adaptive strategies for each platform.</li>
      </ul>
      <p><strong>Example:</strong> Use Instagram ads for younger audiences and LinkedIn ads for B2B clients.</p>

      <h2>6. Value with high ROI 🏷️</h2>
      <p>Performance marketing is one of the most cost-effective strategies. With detailed performance monitoring and optimization, you can allocate your budget wisely,
      focusing on campaigns that produce the best results.</p>
      <ul>
        <li>Every dollar spent is counted.</li>
        <li>Optimized costs reduce financial risk.</li>
      </ul>
      <p><strong>Pro Tip:</strong> Start with a small budget. Analyze results and gradually scale successful campaigns.</p>

      <h2>7. Easily Scalable for Business Growth 📈</h2>
      <p>One of the finest strengths of performance marketing is its scalability. Whether you’re a startup or a longstanding enterprise, you can start small and
      expand as you see outcomes. Successful campaigns may be scaled to reach more people, generate more leads, and drive greater sales.</p>
      <ul>
        <li>Support growth at your pace.</li>
        <li>Flexible for businesses with varying budgets.</li>
      </ul>
      <p><strong>Example:</strong> A startup can start with a $500 ad budget and gradually grow it as sales increase, ensuring consistent and sustainable growth.</p>

      <h2>How to Get Started with Performance Marketing</h2>
      <p>Performance advertising might sound complicated, but with the right partner, it can transform your business. At Saptam Technologies, we specialize
      in developing data-driven campaigns tailored to your specific goals. Our team helps you:</p>
      <ul>
        <li>Identify your target market.</li>
        <li>Choose the proper platforms.</li>
        <li>Optimize campaigns for maximum ROI.</li>
      </ul>

      <h2>Conclusion</h2>
      <p>Performance marketing isn’t just a buzzword—it’s a powerful tool for businesses looking to achieve measurable results. Whether you’re aiming to grow
      profits, generate leads, or enhance brand awareness, performance marketing ensures that your efforts are cost-effective and impactful.</p>
      <p>Ready to take your business to the next level? Let’s create a performance marketing strategy that drives real results for your business. Contact <a href="https://wa.me/917357932921" target="_blank" rel="noopener noreferrer">Saptam Technologies</a> today!</p>
    `,
  },
  
];

function Blog() {
  const { slug } = useParams(); // Use `slug` instead of `id`
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when the component loads or slug changes
  }, [slug]);

  // Find the blog with the matching slug
  const blog = blogs.find((blog) => blog.slug === slug);

  if (slug) {
    if (!blog) {
      return <h2>Blog not found</h2>;
    }

    return (
      <div className="full-blog">
        <h1>{blog.title}</h1>
        <img src={blog.image} alt={blog.title} className="blog-image" />
        <div
          className="blog-content"
          dangerouslySetInnerHTML={{ __html: blog.fullContent }}
        />
        <button onClick={() => navigate("/blog")} className="back-button">
          Back to Blogs
        </button>
      </div>
    );
  }

  // Render the BlogCardCarousel if no slug is in the URL
  return (
    <div className="blog-carousel-wrapper">
      <BlogCardCarousel blogs={blogs} />
    </div>
  );
}

export default Blog;
