import React, { useEffect, useState } from 'react';
import './ContactUs.css';
import { MdEmail, MdLocationOn, MdPhone } from 'react-icons/md';

function ContactUs() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: '',
    message: ''
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, phone, service, message } = formData;

    try {
      let formDataToSend = new FormData();
      formDataToSend.append('name', name);
      formDataToSend.append('email', email);
      formDataToSend.append('phone', phone);
      formDataToSend.append('message', message);
      formDataToSend.append('service', service);

      const response = await fetch(
        'https://script.google.com/macros/s/AKfycby1hIlMLtO3b5j-wPsGfly--PGQz4_3CoQyAVozrBdZQGt2KEReeaQoYUjABs3lZZDbUg/exec',
        {
          method: 'POST',
          body: formDataToSend
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const messages = await response.text();
      console.log('Response from Google Sheets:', messages);
      setSuccess(true);

      setFormData({
        name: '',
        email: '',
        phone: '',
        service: '',
        message: ''
      });

      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    }
  };

  return (
    <div className="contact-us-container">
      <h2>
        Contact <span>Us</span>
      </h2>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-left">
          <div className="form-group-left">
            <h3>Pleased to Connect – How Can We Assist You?</h3>
          </div>

          <div className="form-group bordered">
            <p>Call Us: </p>
            <strong>
              <span className="icon-text">
                <MdPhone className="icon" />+91 7357932921
              </span>
            </strong>
          </div>
          <div className="form-group bordered">
            <p>Send Mail: </p>
            <a href="mailto:info@saptamtech.com" className="icon-text">
              <MdEmail className="icon" />info@saptamtech.com
            </a>
          </div>
          <div className="form-group bordered">
            <p>Our Office Location:</p>
            <strong>
              <span className="icon-text">
                <MdLocationOn className="icon" />Sector 1, Malviya Nagar, Jaipur, India, 302017
              </span>
            </strong>
          </div>
        </div>
        <div className="form-right">
          <div className="form-group">
            {/* <label>Your Name*</label> */}
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your full name*"
              required
            />
          </div>
          <div className="form-group">
            {/* <label>Your Email Address*</label> */}
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email address*"
              required
            />
          </div>
          <div className="form-group">
            {/* <label>Contact Number*</label> */}
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter your contact number*"
              required
            />
          </div>
          <div className="form-group">
            {/* <label>The Services You're Interested In*</label> */}
            <select
              name="service*"
              value={formData.service}
              onChange={handleChange}
              required
            >
              <option value="">Select a service*</option>
              <option value="Website Development">Website Development</option>
              <option value="Google & Facebook Ads">Google & Meta Ads</option>
              <option value="Social Media Handling">Social Media Handling</option>
              <option value="Graphic Designing">Graphic Designing</option>
              <option value="Ecommerce Development">E-commerce Development</option>
              <option value="Answer Engine Optimisation">Answer Engine Optimisation</option>
            </select>
          </div>
          <div className="form-group">
            {/* <label>Message*</label> */}
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Write your message here*"
              required
            ></textarea>
          </div>
          <div className="submit-container">
            <button type="submit" className="submit-btn">
              Submit
            </button>
          </div>
        </div>
      </form>

      {success && <p className="success-message">Message sent successfully!</p>}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

export default ContactUs;
