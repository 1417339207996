import React from 'react';
import { Link } from 'react-scroll';
import './Home.css';

function Home() {

  return (
    <>
      <section className="home-section">
        <div className="home-content">
          <div className="text-content">
            <h1>Digital Marketing <span className="and"> and </span> Web Development <span className='h2'>solutions to fuel your business growth.</span></h1>
            <p>"Take your brand to new heights, captivate and engage your audience in meaningful ways, and unlock the full potential of your online presence to drive long-term success and growth in the digital world!"</p>

            <Link
              to="services"  // Target the section with the ID 'services'
              spy={true}      // Enables scrolling tracking
              smooth={true}   // Enables smooth scrolling
              duration={200}  // Time duration for scroll
            >
              <button className="explore-button">Explore Our Services</button>
            </Link>
          </div>
          <div className="image-content">
            <img
              src="/homePage.webp"
              alt="Web Developer Illustration"
            />
          </div>

        </div>
        <div className="footer-icons">
          <img src="/GoogleIcons.webp" alt="Google" />
          <span className="img1">
            <img src="/GoogleA.webp" alt="Google Analytics" />
          </span>
          <img src="/GoogleAd.webp" alt="Google Ads" />
          <img src="/Meta.webp" alt="Meta" />
          <img src="/facebook.webp" alt="Facebook" />
          <img src="/instagram.webp" alt="Instagram" />
        </div>
      </section>

      <section className="sales-funnel-section">
        <div className="sales-funnel-content">
          <div className="funnel-image">
            <h2>Sales <span>Funnel</span></h2>
            <img src="/funnel.webp" alt="Sales Funnel Illustration" />
          </div>
          <div className="funnel-table">
            <table className="sales-table">
              <thead>
                <tr>
                  <th>Objective</th>
                  <th>Content Examples</th>
                  <th>Key Strategies</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Introduce your brand and attract potential customers</td>
                  <td>Blog posts, social media ads, infographics, SEO-optimized content, educational videos</td>
                  <td>Create informative, engaging content and use platforms like social media and search engines to reach a broader audience</td>
                </tr>
                <tr>
                  <td>Engage and build curiosity about your offerings</td>
                  <td>Newsletters, case studies, explainer videos, interactive social posts, eBooks</td>
                  <td>Share value-driven content like webinars and free resources to build trust and maintain interest</td>
                </tr>
                <tr>
                  <td>Show how your product/service solves their problems</td>
                  <td>Detailed product pages, comparison guides, testimonials, FAQs, email campaigns</td>
                  <td>Highlight your USPs, success stories, and provide clear solutions to customer pain points</td>
                </tr>
                <tr>
                  <td>Motivate prospects to take the next step</td>
                  <td>Discounts, limited-time offers, personalized emails, free trials, consultation calls</td>
                  <td>Create urgency with exclusive offers and use personalized touchpoints to drive conversions</td>
                </tr>
                <tr>
                  <td>Convert leads into customers</td>
                  <td>Checkout page, thank-you emails, user onboarding materials, upselling offers</td>
                  <td>Ensure a smooth checkout process, follow up to enhance satisfaction, and suggest complementary products or services</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

    </>
  );
}

export default Home;
