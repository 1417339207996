import React from 'react';
import { Link } from 'react-router-dom';
import './BlogCard.css';

function BlogCard({ blogs = [] }) {
  // Divide blogs into chunks of 4 cards
  const chunkSize = 4;
  const chunks = [];
  for (let i = 0; i < blogs.length; i += chunkSize) {
    chunks.push(blogs.slice(i, i + chunkSize));
  }

  return (
    <div className="container">
      <h1 className="heading">Our Blogs</h1>

      {chunks.map((chunk, chunkIndex) => (
        <div className="blog-card-row" key={chunkIndex}>
          {chunk.map((blog) => (
            <div className="blog-card" key={blog.slug}>
              <h2 className="blog-card-title">{blog.title}</h2>
              <img src={blog.image} alt={blog.title} className="blog-image" />
              <p className="blog-card-preview">{blog.preview}</p>
              <Link to={`/blog/${blog.slug}`} className="read-more-button">
                Read More
              </Link>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default BlogCard;
