import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import "./Navbar.css";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
    setServicesOpen(false);
  };

  const toggleServices = (e) => {
    e.preventDefault();
    setServicesOpen(!servicesOpen);
  };

  const isHomePage = location.pathname === '/';

  const services = [
    { name: "Website Development", id: "website-development" },
    { name: "Google & Meta Ads", id: "google-facebook-ads" },
    { name: "Social Media Management", id: "social-media-management" },
    { name: "Graphic Designing", id: "graphic-designing" },
    { name: "E-commerce Development", id: "ecommerce-development" },
    { name: "Answer Engine Optimization", id: "answer-engine-optimization" },
  ];

  const handleLinkClick = (to) => {
    if (!isHomePage) {
      const element = document.getElementById(to);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 200);
      }
    }
    closeMenu();
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <RouterLink to="/">
          <img src="/LogoSaptam.webp" alt="Logo"/>
        </RouterLink>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        &#9776;
      </div>
      <div className={`overlay ${isOpen ? 'open' : ''}`} onClick={closeMenu}></div>
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <li>
          {isHomePage ? (
            <ScrollLink
              to="home"
              spy={true}
              smooth={true}
              duration={200}
              onClick={closeMenu}
            >
              Home
            </ScrollLink>
          ) : (
            <RouterLink to="/" onClick={() => handleLinkClick('home')}>
              Home
            </RouterLink>
          )}
        </li>
        <li>
          {isHomePage ? (
            <ScrollLink
              to="about"
              spy={true}
              smooth={true}
              duration={200}
              onClick={closeMenu}
            >
              About Us
            </ScrollLink>
          ) : (
            <RouterLink to="/" onClick={() => handleLinkClick('about')}>
              About Us
            </RouterLink>
          )}
        </li>
        <li className="relative">
          {isHomePage ? (
            <div>
              <ScrollLink
                spy={true}
                smooth={true}
                duration={200}
                onClick={toggleServices}
                className="flex items-center"
              >
                Services
                <span className="ml-1">▼</span>
              </ScrollLink>
              {servicesOpen && (
                <ul className="absolute z-10 left-0 mt-2 w-56 rounded-md shadow-lg bg-white">
                  {services.map((service) => (
                    <li key={service.id} style={{ listStyle: 'disc', marginLeft: '20px' }}>
                      <RouterLink
                        to={`/${service.id}`} // Link to the individual service page
                        onClick={closeMenu}
                        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                      >
                        {service.name}
                      </RouterLink>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ) : (
            <div>
              <RouterLink
                to="/"
                onClick={toggleServices}
                className="flex items-center"
              >
                Services
                <span className="ml-1">▼</span>
              </RouterLink>
              {servicesOpen && (
                <ul className="absolute z-10 left-0 mt-2 w-56 rounded-md shadow-lg bg-white">
                  {services.map((service) => (
                    <li key={service.id} style={{ listStyle: 'disc', marginLeft: '20px' }}>
                      <RouterLink
                        to={`/${service.id}`} // Link to the individual service page
                        onClick={() => handleLinkClick(service.id)}
                        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                      >
                        {service.name}
                      </RouterLink>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </li>
        <li>
          {isHomePage ? (
            <ScrollLink
              to="portfolio"
              spy={true}
              smooth={true}
              duration={200}
              onClick={closeMenu}
            >
              Portfolio
            </ScrollLink>
          ) : (
            <RouterLink to="/" onClick={() => handleLinkClick('portfolio')}>
              Portfolio
            </RouterLink>
          )}
        </li>
        <li>
          <RouterLink to="/contact-us" onClick={closeMenu}>
            Contact Us
          </RouterLink>
        </li>
        <li>
          <a
            href="https://wa.me/917357932921"
            target="_blank"
            rel="noopener noreferrer"
            className="button-link"
          >
            Get a Free Consultation
          </a>
        </li>

      </ul>
    </nav>
  );
}

export default Navbar;