import React from 'react';
import './AboutUs.css';

function AboutUs() {
  return (
    <div className="about-us-container">
      <h1 className="about-us-title"><span>SAPTAM </span>TECHNOLOGIES</h1>
      <div className="about-us-content">
        <div className="about-us-image">
          <img src="/rb_727.webp" alt="Team working illustration" />
        </div>
        <div className="about-us-text">
          <p className="about-us-description">
            Saptam Technologies is a Performance Marketing and Web Development agency committed to driving business
            growth through tailored solutions. We specialize in social media marketing, PPC, content marketing, and
            email campaigns to boost traffic and conversions. Our expert web development team creates responsive,
            high-performance websites that provide seamless user experiences, while our branding and design services
            help you build a strong, memorable brand identity. For businesses looking to expand online, we offer
            comprehensive e-commerce solutions, and our performance marketing ensures maximum ROI with data-driven strategies.
            <br />
            By partnering with us, clients benefit from customized strategies aligned with their goals, expert industry
            knowledge, and end-to-end support. We focus on delivering measurable, actionable results, helping you track
            progress and continuously improve.
            <br />
            Saptam Technologies is your trusted partner in navigating the digital landscape, driving your business to new
            heights with innovative solutions.
          </p>
          <h5 className="about-us-ceo">Founder: Ankit Sharma</h5>
        </div>
      </div>

      <div className="about-us-stats">
        <div className="stat-item">
          <h2>1+</h2>
          <p>Years Of Experience</p>
        </div>
        <div className="stat-item">
          <h2>5+</h2>
          <p>Satisfied Clients</p>
        </div>
        <div className="stat-item">
          <h2>10+</h2>
          <p>Projects Completed</p>
        </div>
        {/* <div className="stat-item">
          <h2>3+</h2>
          <p>Country Served</p>
        </div> */}
      </div>
    </div>
  );
}

export default AboutUs;
