import React, { useEffect } from 'react';
import './pricing.css';

function Pricing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pricing-page">
      <div className="pricing-article">
        <h1>Transparent and Tailored Pricing for Your Business</h1>
        <div className="pricing-section">
          <p>
            At Saptam Technologies, we accept as true that each venture is precise, and so are its requirements. Whether you're looking for
            internet site improvement, virtual advertising and marketing, SEO, or eCommerce answers, we offer customized programs that match
            your particular wishes and dreams.
          </p>
          <h2>What You Get with Our Services:</h2>
          <ul>
            <li>✔️ Expert Solutions: Crafted through enterprise professionals to deliver outstanding outcomes.</li>
            <li>✔️ Scalable Plans: Designed to grow with your commercial enterprise.</li>
            <li>✔️ Budget-Friendly Options: Get maximum cost without compromising greatly.</li>
            <li>✔️ 100% Transparency: No hidden expenses, simply clear communication.</li>
          </ul>
          <h2>How It Works:</h2>
          <ol>
            <li>Consultation: Share your requirements and desires with us.</li>
            <li>Customized Plan: We’ll create a tailored strategy primarily based on your needs.</li>
            <li>Quotation: Receive a clean breakdown of offerings and fees.</li>
            <li>Execution: Watch your imaginative and prescient come to life with our understanding.</li>
          </ol>
          <h2>Explore Pricing for:</h2>
          <ul>
            <li>Website Development</li>
            <li>eCommerce Solutions</li>
            <li>Search Engine Optimization (SEO) Services</li>
            <li>Digital Marketing Campaigns</li>
            <li>Social Media Management</li>
          </ul>
          <h3>Let’s Get Started!</h3>
          <p>
            Ready to take your business to the subsequent degree? 🌟 Contact us nowadays to schedule a session and get a customised
            citation.
          </p>
          <p>
            📩 <a href="mailto:info@saptamtech.com" style={{ color: '#007BFF', textDecoration: 'underline' }}>Get a Free Quote</a> <br />
            📞 <a href="https://wa.me/917357932921"
            target="_blank"
            rel="noopener noreferrer" style={{ color: '#007BFF', textDecoration: 'underline' }}>Contact Us Now</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
