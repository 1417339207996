// Portfolio.js
import React from 'react';
import './Portfolio.css';

function Portfolio() {
  return (
    <div className="portfolio-container">
      <div className="portfolio-title">
        <h2>Businesses</h2>
        <p>We've Worked With</p>
      </div>
      <div className="portfolio-logos">
        <div className="logo-card dark-card">
          <img src="/momaa.webp" alt="Momaa Foods" width="200" // Replace with the actual width
      height="100"/>
          <p>Momaa Foods</p>
        </div>
        <div className="logo-card light-card">
          <img src="/shre.webp" alt="Shree Sawariya Seva Sansthan" width="200" // Replace with the actual width
      height="100"/>
          <p>Shree Sawariya Seva Sansthan</p>
        </div>
        <div className="logo-card dark-card">
          <img src="/rabbitone.webp" alt="RabitTone" width="200" // Replace with the actual width
      height="100"/>
          <p>RabitTone</p>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
