import React, { useEffect } from 'react';
import './privacy.css';

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy-policy-container">
     
      <header className="privacy-policy-header">
        <h1>Privacy Policy</h1>
        <p>
          At Saptam Technologies, we take your privacy seriously. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website and services.
        </p>
      </header>

      <div className="privacy-section">
        <h2>1. Information We Collect</h2>
        <p>
          We collect information that you provide to us directly, such as when you contact us via forms, sign up for services, or use certain features of our website.
        </p>
      </div>

      <div className="privacy-section">
        <h2>2. How We Use Your Information</h2>
        <p>
          We use your information to provide and improve our services, process transactions, communicate with you, and for other purposes as detailed in this policy.
        </p>
      </div>

      <div className="privacy-section">
        <h2>3. Data Protection</h2>
        <p>
          We implement security measures to protect your personal information from unauthorized access or disclosure.
        </p>
      </div>

      <div className="privacy-section">
        <h2>4. Sharing of Information</h2>
        <p>
          We do not sell or rent your personal information to third parties. We may share information in certain cases, such as when required by law.
        </p>
      </div>
      <div className="privacy-section">
        <h2>5. Your Rights</h2>
        <p>
          You have the right to access, update, or delete your personal information. You may also opt-out of certain communications or use of your data.
        </p>
      </div>

      <div className="privacy-section">
        <h2>6. Cookies</h2>
        <p>
          We may use cookies to enhance your experience on our website. You can control cookies through your browser settings.
        </p>
      </div>

      <div className="privacy-section">
        <h2>7. Changes to this Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page with the updated date.
        </p>
      </div>

      <div className="privacy-section contact-section">
        <h2>8. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <p>
          Email: <a href="mailto:info@saptamtech.com">info@saptamtech.com</a>
        </p>
        <p>Phone: +91 7357932921</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
