import React from 'react';
import './Technologies.css'; // Style for this component

const Technologies = () => {
  const techData = [
    { name: 'HTML5', icon: '/html5.webp' },
    { name: 'CSS3', icon: '/css3.webp' },
    { name: 'JavaScript', icon: '/javascript.webp' },
    { name: 'React', icon: '/react.webp' },
    { name: 'Node.js', icon: '/nodejs.webp' },
    { name: 'Wordpress', icon: '/wordpress.webp' },
    { name: 'Shopify', icon: '/shopify.webp' },
    { name: 'Meta Business Suite', icon: '/metasuite.webp' },
    { name: 'Meta Ads Manager', icon: '/facebookadsmanager.webp' },
    { name: 'Google Analytics', icon: '/googleanalytics.webp' },
    { name: 'Google Ads Manager', icon: '/adsmanager.webp' },
    { name: 'Google Trends', icon: '/googletrends.webp' },
    { name: 'Google Search Console', icon: '/googlesearch.webp' },
    { name: 'PageSpeed Insights', icon: '/pagespeedinsight.webp' },
    { name: 'Ubersuggest', icon: '/ubersuggest.webp' },
    { name: 'Ahrefs', icon: '/ahrefs.webp' },
    { name: 'Semrush', icon: '/semrush.webp' },
    { name: 'Answer The Public', icon: '/atp.webp' },
  ];

  return (
    <div className="technologies-section">
      <h2>The Backbone of Our <span>Digital Solutions</span></h2>
      <div className="technologies-grid">
        {techData.map((tech, index) => (
          <div key={index} className="technology-card">
            <img src={tech.icon} alt={`${tech.name} logo`} className="tech-icon" />
            <h3>{tech.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Technologies;
