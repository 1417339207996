import React, { useState } from 'react';
import './Testimonials.css'; 

const testimonialsData = [
  {
    company: "INNX",
    quote: "Saptam Technologies delivered a seamless, responsive website for our business. They understood our needs perfectly and provided a modern design with excellent functionality. The project was completed on time and exceeded expectations. Highly recommended!",
    name: "Sarah P.",
    position: "CEO",
    avatar: "/images1.webp"
  },
  {
    company: "Crafty Creations",
    quote: "Saptam Technologies created a stunning logo and brand materials for us. Their design team is creative, professional, and attentive to detail. Our branding has never looked better. We're thrilled with the results!",
    name: "Michael R.",
    position: "Owner",
    avatar: "/images1.webp"
  },
  {
    company: "G-Glow Solutions",
    quote: "Working with Saptam Technologies for social media management has been fantastic. They've boosted our online presence with great content and increased engagement. Our brand visibility has grown significantly. They truly know social media!",
    name: "Jessica T.",
    position: "Marketing Director",
    avatar: "/images1.webp"
  }
];

function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isOddCount = testimonialsData.length % 2 !== 0;
  const totalPairs = Math.ceil(testimonialsData.length / 2);

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex - 2;
      if (isOddCount && newIndex < 0) {
        return testimonialsData.length - 1;
      }
      return newIndex < 0 ? testimonialsData.length - 2 : newIndex;
    });
  };

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + 2;
      if (isOddCount && prevIndex === testimonialsData.length - 1) {
        return 0;
      }
      return newIndex >= testimonialsData.length ? 0 : newIndex;
    });
  };

  const getVisibleTestimonials = () => {
    const visibleTestimonials = [];
    const firstCard = testimonialsData[currentIndex];
    visibleTestimonials.push(firstCard);

    // Add second card only if we're not at the last odd card
    if (!(isOddCount && currentIndex === testimonialsData.length - 1)) {
      const secondCardIndex = (currentIndex + 1) % testimonialsData.length;
      if (secondCardIndex !== currentIndex) {
        visibleTestimonials.push(testimonialsData[secondCardIndex]);
      }
    }

    return visibleTestimonials;
  };

  return (
    <div className="testimonials-container">
      <h2 className="testimonials-title">
        Still Hesitant? Let Our <span>Happy Clients'<br />Stories</span> Convince You!
      </h2>

      <div className="testimonials-carousel">
        <div className="testimonials-cards">
          {getVisibleTestimonials().map((testimonial, idx) => (
            <div key={idx} className="testimonial-card">
              <h3 className="company-name">{testimonial.company}</h3>
              <p className="quote">"{testimonial.quote}"</p>
              <div className="user-info">
                <div className="user-avatar">
                  <img src={testimonial.avatar} alt={testimonial.name} />
                </div>
                <div>
                  <p className="user-name">{testimonial.name}</p>
                  <p className="user-position">{testimonial.position}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="pagination-dots">
          {[...Array(totalPairs)].map((_, index) => (
            <span
              key={index}
              className={`dot ${index === Math.floor(currentIndex / 2) ? 'active' : ''}`}
            ></span>
          ))}
        </div>

        <div className="carousel-navigation">
          <button onClick={prevTestimonial} className="prev-btn">❮</button>
          <button onClick={nextTestimonial} className="next-btn">❯</button>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
